<template>
  <header id="admin-header">
    <div id="logo">
      <h2>
        <a class="link-unstyled" href="#" @mousedown.prevent.stop="admin"
          >Urban Nature Explorer Admin</a
        >
      </h2>
    </div>
    <div id="breadcrumb" v-if="$route.path != '/Admin'">
      <a class="link-unstyled" id="br-root" @mousedown.prevent.stop="admin"
        >Projects</a
      >
      <span class="separator"> > </span>
      <h2 id="br-project">
        {{
          $store.state.currentProject ? $store.state.currentProject.name : ""
        }}
      </h2>
      <!-- <span v-if="$store.state.scenario"> >
				<span id="br-scenario">{{ $store.state.scenario ? $store.state.scenario.title : '' }}</span>
			</span> -->
    </div>
  </header>
</template>
<script>
export default {
  methods: {
    admin: function () {
      this.$store.commit("setCurrentProject", null);
      this.$router.push("/Admin");
    },
  },
};
</script>
<style lang="scss" scoped>
#admin-header {
  display: flex;
  justify-content: space-between;

  #logo {
    display: flex;
    margin-bottom: 30px;

    h2 {
      width: 175px;
    }
  }
  .separator {
    margin: 0 10px;
  }
  #breadcrumb {
    height: 86px;
    display: flex;
    align-items: center;
    > * {
      display: inline-block;
    }
  }
}
</style>
