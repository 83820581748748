import Vue from "vue";
import App from "./App.vue";
//import BootstrapVue from 'bootstrap-vue'

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

import "mapbox-gl/dist/mapbox-gl.css";
import "@maupalantir/mapbox-gl-draw/dist/mapbox-gl-draw.css";

//Vue.use(BootstrapVue)
Vue.config.productionTip = false;

import router from "./router";
import store from "./store";

new Vue({
  router,
  store,
  render(h) {
    return h(App);
  },
}).$mount("#app");

Vue.config.errorHandler = (err) => {
  // err: error trace
  // vm: component in which error occured
  // info: Vue specific error information such as lifecycle hooks, events etc.

  // TODO: Perform any custom logic or log to server

  console.error(err);
};

window.onerror = function (message, source, lineno, colno, error) {
  console.error(message, source, lineno, colno, error);
};
