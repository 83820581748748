import Vue from "vue";
import Router from "vue-router";
import { currentUser } from "./store";

Vue.use(Router);

const StartPage = () => import("./routes/StartPage.vue");
const About = () => import("./routes/About.vue");
const Privacy = () => import("./routes/Privacy.vue");

const SelectProject = () => import("./routes/SelectProject.vue");

const ExploreSite = () => import("./routes/ExploreSite.vue");
const SelectScenario = () => import("./routes/SelectScenario.vue");
const ImplementationGame = () => import("./routes/ImplementationGame.vue");
const Evaluation = () => import("./routes/Evaluation.vue");
const EvaluationPage = () => import("./routes/EvaluationPage.vue");
const ResetPassword = () => import("./routes/ResetPassword.vue");

const AdminDashboard = () => import("./routes/admin/Dashboard.vue");
//const AdminNbsTypes = () => import('./routes/admin/NbsTypes.vue')
const AdminProjectDashboard = () =>
  import("./routes/admin/ProjectDashboard.vue");
const AdminScenarioDashboard = () => import("./routes/admin/Scenario.vue");
const AdminPredefined = () => import("./routes/admin/PredefinedAreas.vue");
const AdminProjectLocation = () => import("./routes/admin/ProjectLocation.vue");

const routes = [
  { path: "/SelectProject", component: SelectProject },
  { path: "/SelectScenario", component: SelectScenario },
  { path: "/ExploreSite", component: ExploreSite },
  { path: "/ImplementationGame", component: ImplementationGame },
  { path: "/Evaluation", component: Evaluation },
  {
    path: "/Solution/:solution",
    component: EvaluationPage,
    meta: { public: true },
  },
  { path: "/ResetPassword", component: ResetPassword, meta: { public: true } },
  { path: "/Admin", component: AdminDashboard, meta: { admin: true } },
  //    { path: '/Admin/NbsTypes', component: AdminNbsTypes, meta: {admin: true} },
  {
    path: "/Admin/Scenario",
    component: AdminScenarioDashboard,
    meta: { admin: true },
  },
  {
    path: "/Admin/Predefined",
    component: AdminPredefined,
    meta: { admin: true },
  },
  {
    path: "/Admin/ProjectLocation",
    component: AdminProjectLocation,
    meta: { admin: true },
  },
  {
    path: "/Admin/ProjectDashboard",
    component: AdminProjectDashboard,
    meta: { admin: true },
  },
  { path: "/About", component: About, meta: { public: true } },
  { path: "/Privacy", component: Privacy, meta: { public: true } },
  { path: "/:project", component: StartPage, meta: { public: true } },
  { path: "/", component: StartPage, meta: { public: true } },
];

let router = new Router({
  base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  let user = currentUser();
  if (to.matched.some((record) => record.meta.public)) {
    next();
  } else if (
    to.matched.some((record) => record.meta.admin) &&
    user &&
    user.customData.admin === true
  ) {
    next();
  } else if (!to.matched.some((record) => record.meta.admin) && user) {
    next();
  } else {
    try {
      next("/");
    } catch (e) {
      console.error(e);
    }
  }
});

export default router;
