import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
//import localForage from 'localforage';

import * as Realm from "realm-web";
const app = new Realm.App({ id: process.env.VUE_APP_STITCH_APP });
let dbName = process.env.VUE_APP_STITCH_DB;
let db = app.services.mongodb("cluster-antheos").db(dbName);

let loadCollection = function (collection, query = {}) {
  return db.collection(collection).find(query, { sort: { order: 1 } });
};

let insertOne = function (collection, doc) {
  return db
    .collection(collection)
    .insertOne(doc)
    .then((result) => {
      doc._id = result.insertedId;
    });
};

let deleteOne = function (collection, query) {
  return db.collection(collection).deleteOne(query);
};

let deleteMany = function (collection, query) {
  return db.collection(collection).deleteMany(query);
};

let numberTypes = function (payload) {
  for (let k in payload) {
    if (payload.hasOwnProperty(k)) {
      if (payload[k] === "") {
        delete payload[k];
      }
      if (typeof payload[k] === "object") {
        payload[k] = numberTypes(payload[k]);
      } else if (
        typeof payload[k] === "string" &&
        payload[k].match(/^\d+\.\d+$/)
      ) {
        payload[k] = { $numberDouble: payload[k] };
      }
    }
  }
  return payload;
};

let saveOne = function (collection, doc, idType = "oid") {
  let id = doc._id;
  let payload = JSON.parse(JSON.stringify(doc));
  delete payload._id;

  payload = numberTypes(payload);
  id = idType === "oid" ? { $oid: id.toString() } : id.toString();

  return db.collection(collection).updateOne({ _id: id }, { $set: payload });
};

Vue.use(Vuex);

export function logIn(creds) {
  return new Promise((resolve, reject) => {
    let credentials = Realm.Credentials.emailPassword(
      creds.email,
      creds.password
    );

    app
      .logIn(credentials)
      .then((user) => {
        // Authenticate the user
        // `App.currentUser` updates to match the logged in user
        if (user.id !== app.currentUser.id) {
          throw "Current user id doesn't match.";
        }
        resolve(user);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function loginAnonymous() {
  // Create an anonymous credential
  return new Promise((resolve, reject) => {
    let credentials = Realm.Credentials.anonymous();
    // Authenticate the user
    app
      .logIn(credentials)
      .then((user) => {
        // `App.currentUser` updates to match the logged in user
        if (user.id !== app.currentUser.id) {
          throw "Current user id doesn't match.";
        }

        resolve(user);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function signUp(creds) {
  return new Promise((resolve, reject) => {
    app.emailPasswordAuth
      .registerUser(creds.email, creds.password)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function sendResetLink(email) {
  return new Promise((resolve, reject) => {
    app.emailPasswordAuth
      .sendResetPasswordEmail(email)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function resetPassword(password, token, tokenId) {
  return new Promise((resolve, reject) => {
    app.emailPasswordAuth
      .resetPassword(token, tokenId, password)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function confirmEmail(token, tokenId) {
  return new Promise((resolve, reject) => {
    app.emailPasswordAuth
      .confirmUser(token, tokenId)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function currentUser() {
  return app.currentUser;
}

export function deleteCloudinaryImage(publicId) {
  return app.currentUser.functions.callFunction("deleteImage", publicId);
}

const vuexStorage = new VuexPersist({
  key: "sourcelink",
  storage: window.localStorage,
  //storage: localForage,
  //asyncStorage: true,
  //You can change this explicitly use
  // either window.localStorage  or window.sessionStorage
  // However we are going to make use of localForage
});

export default new Vuex.Store({
  plugins: [vuexStorage.plugin],
  state: {
    step: null,
    currentPage: null,
    currentProject: null,
    scenario: null,
    solution: null,
    nbsTypes: {},
    nbsCategories: {},
    currentNbs: {},
    challenges: [],
    // All the scenarios the user has access.
    scenarios: {},
    sites: {},
    indicators: {},
    cities: [],
  },
  mutations: {
    auth(state, user) {
      state.user = user;
    },
    setStep(state, step) {
      state.step = step;
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setCurrentNbs(state, nbs) {
      state.currentNbs = nbs;
    },
    setCurrentProject(state, workshop) {
      state.currentProject = workshop;
    },
    setScenario(state, scenario) {
      state.scenario = scenario;
    },
    setSolution(state, solution) {
      state.solution = solution;
    },
    setChallenges(state, challenges) {
      state.challenges = challenges;
    },
    setIndicators(state, indicators) {
      state.indicators = indicators;
    },
    setNbsTypes(state, types) {
      state.nbsTypes = types;
    },
    setNbsCategories(state, types) {
      state.nbsCategories = types;
    },
    setScenarios(state, scenarios) {
      state.scenarios = scenarios;
    },
    setCities(state, cities) {
      state.cities = cities;
    },
    pushScenarios(state, scenario) {
      state.scenarios[scenario._id.toString()] = scenario;
    },
    setScenarioTarget(state, indicator) {
      if (state.scenario.targets === undefined) {
        state.scenario.targets = [];
      }
      let existingIndicator = state.scenario.targets.find((item) => {
        return item.indicator == indicator.indicator;
      });
      let index = state.scenario.targets.indexOf(existingIndicator);
      if (index >= 0) {
        state.scenario.targets[index] = indicator;
      } else {
        state.scenario.targets.push(indicator);
      }
    },
    setSites(state, sites) {
      state.sites = sites;
    },
    setSite(state, site) {
      state.site = site;
    },
  },
  getters: {
    nbsTypeOptions: (state) => {
      let types = {};
      Object.values(state.nbsTypes).forEach((type) => {
        types[type._id] = type.name;
      });
      return types;
    },
  },
  actions: {
    resetUserState({ commit }) {
      commit("setStep", null);
      commit("setSites", {});
      commit("setSolution", null);
    },
    loadNbsTypes({ commit }) {
      return new Promise((resolve, reject) => {
        let types = {};
        return loadCollection("nbsTypes")
          .then((_types) => {
            _types.forEach((type) => {
              if (type.object) {
                if (!type.object.options) {
                  type.object.options = {};
                }
                type.object.options.nbsType = type._id;
              }
              types[type._id.toString()] = type;
            });
            commit("setNbsTypes", types);
            resolve(types);
          })
          .catch(() => {
            reject();
          });
      });
    },
    loadNbsCategories({ commit }) {
      return new Promise((resolve, reject) => {
        let types = {};
        return loadCollection("nbsCategories")
          .then((_types) => {
            _types.forEach((type) => {
              types[type._id.toString()] = type;
            });
            commit("setNbsCategories", types);
            resolve(types);
          })
          .catch(() => {
            reject();
          });
      });
    },

    loadProjects(object, query = {}) {
      return new Promise((resolve, reject) => {
        return loadCollection("workshops", query)
          .then((workshops) => {
            //resolve(workshops)
            workshops.forEach((workshop, i) => {
              let types = {};
              workshops[i]["_id"] = workshops[i]._id.toString();
              workshops[i].nbsTypesRaw = workshops[i].nbsTypes;

              if (workshops[i].nbsTypes) {
                workshops[i].nbsTypes.forEach((type) => {
                  if (type.behavior == undefined) {
                    type.behavior = {};
                  }
                  if (type.object == undefined) {
                    type.object = { options: {} };
                  }
                  if (type.object.options == undefined) {
                    type.object.options = {};
                  }
                  type._id = type._id.toString();
                  types[type._id.toString()] = type;
                });
                workshops[i].nbsTypes = types;
              }
            });

            resolve(workshops);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    reloadProject({ state, commit, dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch("loadProjects", {
          _id: { $oid: state.currentProject._id.toString() },
        })
          .then((workshops) => {
            let project = workshops[0];
            commit("setCurrentProject", project);
            commit("setSites", {});
            commit("setSite", null);
            commit("setScenarios", {});
            resolve(project);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    updateProjectKey(state, data) {
      return new Promise((resolve, reject) => {
        db.collection("workshops")
          .updateOne(
            { _id: { $oid: data.project._id.toString() } },
            { [`${data.method}`]: { [`${data.key}`]: data.data } }
          )
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    createNbsType(state, data) {
      return new Promise((resolve, reject) => {
        db.collection("workshops")
          .updateOne(
            { _id: { $oid: data.project._id } },
            { $push: { nbsTypes: data.data } }
          )
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteNbsType(state, data) {
      return new Promise((resolve, reject) => {
        db.collection("workshops")
          .updateOne(
            { _id: { $oid: data.project._id } },
            { $pull: { nbsTypes: { _id: data.id } } }
          )
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    saveNbsType(state, data) {
      let setObject = {};
      if (data.types) {
        data.types.forEach((t) => {
          setObject[`nbsTypes.${t.id}`] = t.data;
        });
      } else {
        setObject = { [`nbsTypes.${data.id}`]: data.data };
      }
      return new Promise((resolve, reject) => {
        db.collection("workshops")
          .updateOne(
            { _id: { $oid: data.project._id.toString() } },
            { $set: setObject }
          )
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    loadSite({ state, commit }, query = {}) {
      return new Promise((resolve, reject) => {
        return loadCollection("sites", query)
          .then((sites) => {
            let site = sites[0];
            let statesites = state.sites;
            site._id = site._id.toString();
            statesites[site._id.toString()] = site;
            commit("setSites", statesites);
            commit("setSite", site);
            resolve(sites[0]);
          })
          .catch((e) => {
            console.log(e);
            reject();
          });
      });
    },

    createSite(state, site) {
      return new Promise((resolve, reject) => {
        insertOne("sites", site)
          .then(() => {
            resolve(site);
          })
          .catch((e) => {
            console.log(e);
            reject();
          });
      });
    },
    saveSite(state, site) {
      return saveOne("sites", site).catch((e) => {
        console.log(e);
      });
    },

    loadScenarios({ commit }, query = {}) {
      return new Promise((resolve, reject) => {
        return loadCollection("scenarios", query)
          .then((scenarios) => {
            let scenariosWithKeys = {};
            for (let i = 0; i < scenarios.length; i++) {
              let s = scenarios[i];
              s._id = s._id.toString();
              scenariosWithKeys[s._id.toString()] = s;
            }
            commit("setScenarios", scenariosWithKeys);
            resolve(scenariosWithKeys);
          })
          .catch(() => {
            reject();
          });
      });
    },

    loadScenarioOptions(store, workshop_id) {
      return new Promise((resolve, reject) => {
        db.collection("scenarios")
          .aggregate([
            {
              $match: {
                workshop_id: {
                  $eq: workshop_id,
                },
              },
            },
            {
              $project: {
                _id: 1,
                title: 1,
                summary: 1,
                description: 1,
                color: 1,
                keyIndicators: 1,
              },
            },
          ])
          .then((scenarios) => {
            let scenariosWithKeys = {};
            for (let i = 0; i < scenarios.length; i++) {
              let s = scenarios[i];
              s._id = s._id.toString();
              scenariosWithKeys[s._id.toString()] = s;
            }
            resolve(scenariosWithKeys);
          })
          .catch(() => {
            reject();
          });
      });
    },

    loadScenario: ({ commit, state, dispatch }, id) => {
      return new Promise((resolve, reject) => {
        let findScenario = (id) => {
          let scenario = state.scenarios[id];
          if (scenario.site && typeof scenario.site == "string") {
            if (!state.sites[scenario.site]) {
              dispatch("loadSite", { _id: { $oid: scenario.site } }).then(
                (site) => {
                  scenario.site = site;
                  commit("setScenario", scenario);
                  resolve(scenario);
                }
              );
            } else {
              scenario.site = state.sites[scenario.site];
              commit("setScenario", scenario);
              resolve(scenario);
            }
          } else {
            commit("setScenario", scenario);
            resolve(scenario);
          }
        };
        if (!state.scenarios[id]) {
          dispatch("loadScenarios", { _id: { $oid: id.toString() } })
            .then(() => {
              findScenario(id);
            })
            .catch(() => {
              reject();
            });
        } else {
          findScenario(id);
        }
      });
    },

    loadIndicators({ commit }) {
      return new Promise((resolve, reject) => {
        return loadCollection("indicators")
          .then((_indicators) => {
            let indicators = {};
            _indicators.forEach((i) => {
              i._id = i._id.toString();
              indicators[i._id.toString()] = i;
            });
            commit("setIndicators", indicators);
            resolve(indicators);
          })
          .catch(() => {
            reject();
          });
      });
    },

    createScenario({ commit, dispatch }, scenario) {
      return insertOne("scenarios", scenario).then(() => {
        scenario._id = scenario._id.toString();
        commit("pushScenarios", scenario);
        dispatch("loadScenario", scenario._id.toString());
      });
    },

    loadSolution({ commit, dispatch }, id) {
      return new Promise((resolve, reject) => {
        loadCollection("solutions", { _id: { $oid: id } })
          .then((c) => {
            let solution = JSON.parse(JSON.stringify(c))[0];
            if (solution.scenario._id) {
              commit("setSolution", solution);
              resolve(solution);
            } else {
              dispatch("loadScenario", solution.scenario)
                .then((sc) => {
                  solution.scenario = sc;
                  commit("setSolution", solution);
                  resolve(solution);
                })
                .catch((e) => {
                  console.log(e);
                });
            }
          })
          .catch((e) => {
            console.log(e);
            reject();
          });
      });
    },

    createSolution(store, solution) {
      return new Promise((resolve, reject) => {
        insertOne("solutions", solution)
          .then(() => {
            solution._id = solution._id.toString();
            resolve(solution);
          })
          .catch((e) => {
            console.log(e);
            reject();
          });
      });
    },

    saveSolution(state, solution = null) {
      if (!solution) {
        solution = state.solution;
      }
      let _values = JSON.parse(JSON.stringify(solution));
      _values.scenario = _values.scenario._id;
      return saveOne("solutions", _values);
    },

    // saveProject(state, project) {
    // 	//let _values = JSON.parse(JSON.stringify(project));
    // 	_values.nbsTypes = Object.values(_values.nbsTypes)
    // 	delete _values.nbsTypesRaw
    // 	return saveOne('workshops', _values, 'oid');
    // },

    deleteProject(state, project) {
      const site =
        typeof project.site === "object"
          ? project.site._id.toString()
          : project.site;
      if (site) deleteOne("sites", { _id: { $oid: site } });
      return deleteMany("scenarios", {
        project_id: project._id.toString(),
      }).then(() => {
        return deleteOne("workshops", {
          _id: { $oid: project._id.toString() },
        });
      });
    },

    createProject(state, project) {
      let _values = project; //JSON.parse(JSON.stringify(project));
      _values.nbsTypes = Object.values(_values.nbsTypes || {});
      delete _values.nbsTypesRaw;

      return new Promise((resolve, reject) => {
        insertOne("workshops", _values)
          .then(() => {
            _values._id = _values._id.toString();
            resolve(_values);
          })
          .catch((e) => {
            console.log(e);
            reject();
          });
      });
    },

    saveScenario(state, scenario = null) {
      if (!scenario) {
        scenario = state.scenario;
      }
      let _values = JSON.parse(JSON.stringify(scenario));
      _values.site = scenario.site._id
        ? scenario.site._id.toString()
        : scenario.site;
      return saveOne("scenarios", _values);
    },

    deleteScenario(state, scenario) {
      return deleteOne("scenarios", { _id: { $oid: scenario._id.toString() } });
    },

    logOut({ dispatch }) {
      dispatch("resetUserState");
      return app.currentUser ? app.currentUser.logOut() : false;
    },
  },
});
